<template>
    <section class="section-slider">
         <div class="text-center mb-5 w-95 w-md-90 w-lg-70 w-xl-60 mx-auto wow fadeIn" data-wow-delay="100ms">
            <span class="text-uppercase letter-spacing-2 font-weight-700 small mb-3 d-block">Partnereink</span>
         </div>
        <div class="slider">
            <div class="slider-items">
                <img src="../assets/img/logos/wimmer.avif" alt="Wimmer partner">
                <img src="../assets/img/logos/fafem.png" alt="Fafém Bútor partner">
                <img src="../assets/img/logos/kalics_sped.jpg" alt="Kalics sped partner">
                <img src="../assets/img/logos/vivienphoto.png" alt="VivienPhoto partner">
                <img src="../assets/img/logos/linea.png" alt="LINEA M KUBINSZKY Kft. partner">
                <img src="../assets/img/logos/tamatruck.jpeg" alt="Tamatruck Kft partner">
                <img src="../assets/img/logos/holeczpeter.png" alt="Holecz Péter partner">
            </div>
        </div>
    </section>
</template>