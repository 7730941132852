<template>
   <section>
      <div class="container">
         <div class="row">
            <div class="title-header">
               <h3>Pályázat</h3>
            </div>
            <div>
               <img src="../../assets/img/MFF kedvezményezetti infóblokk-1.jpg" />
            </div>
            <div class="title-header">
               <h3>Modern üzleti és termelési kihívásokhoz való alkalmazkodást segítő fejlesztés a BEST-LOG Kft-nél.</h3>
            </div>
            <div class="title-desc">
               <ul class="ul-support">
                  <li>Projekt azonosító száma: GINOP_PLUSZ-1.2.1-21-2021-02060</li>
                  <li>Támogatás összege: 35 918 895 Ft</li>
                  <li>Támogatási arány (%) 70</li>
               </ul>
               <p class="justify-p">
                  A BEST-LOG Kft. sikerrel pályázott a GINOP_PLUSZ-2.1.1-21-2021-02060 azonosító számú
                  projektjével a „Modern üzleti és termelési kihívásokhoz való alkalmazkodást segítő fejlesztés a BEST-
                  LOG Kft-nél” tárgyú felhívásra. A 35,92 millió forint feltételesen visszatérítendő Európai Uniós
                  támogatású projekt a Széchenyi Terv Plusz program keretén belül valósult meg. A projekt
                  megvalósításának kezdete 2021.10.01., fizikai befejezésének napja 2023.08.31.
               </p>
               <p class="justify-p">
                  A Best-Log Kft. 2009-ben alakult. Kezdetektől fő profilja a gépjármű-kereskedelem és a közúti
                  szállítás, szárazföldi szállítást kiegészítő szolgáltatás és raktározás. Rövid idő alatt a régió vezető
                  szállítmányozó cégévé vált Lakitelek és agglomerációjának igényeit kiszolgálva. A telepen a
                  vállalkozás autóipari szolgáltató egységei és a kft. saját tulajdonú - közel 300 m2-es - épülete
                  található, mely minden infrastruktúrával rendelkezik a technológiai és informatikai fejlesztés
                  szempontjából.
               </p>
               <p class="justify-p">
                  A folyamatos fejlődés és tanulás a vállalat alapértékeivel egyezik, hiszen a Dél-Alföldön lévő
                  mikrovállalkozás fenntartásához elengedhetetlen az a piaci plusz érték hozzáadása, amellyel ez a
                  pályázat segítségre lehet. A pályázat révén megvalósított informatikai fejlesztéssel és új eszközök
                  beszerzésével és munkavállalók képzésével, vevőelégedettség mérésével, a vezető munkatársak
                  személyes kompetenciájának hozzáadott értékével a bevétel jelentős része lett növelhető.  
               </p>
               <p class="justify-p">
                  A GINOP Plusz-1.2.1-21 pályázat során, Lakitelek frekventált ipari részén Európai Uniós szintű
                  gépjárműfenntartó műhely fejlesztéséhez szükséges tárgyi feltételeinek egyes berendezései kerültek
                  beszerzésre. Többek között ST-2024P Lengéscsillapító, HPA B-700 Kerékkiegyensúlyozó, RBT-
                  1013/CP/E személy- és teherfékpad és egyéb garázsipari berendezések. IT eszközök és számviteli,
                  pénzügy és további üzleti hatékonyságot növelő felhőalapú ügyviteli rendszer került bevezetésre. Az
                  elnyert támogatás során a vezető kollégák kompetenciájának fejlesztése is megtörtént.
               </p>
               <p class="justify-p">
                  A fejlesztés eredményeként növekedett a Kft. gazdasági teljesítménye és javult a technológiai
                  felkészültsége, versenyképessége, és az erőforrás hatékonysága ezzel erősödött a vállalkozás
                  gazdasági szerepe a régióban.
               </p>
               <h6>További információ kérhető:</h6>
               <p>Szabó Attila ügyvezető, sajtóreferens</p>
               <p>+36209267359</p>
               <p>bestlog@t-online.hu</p>
            </div>
             <div class="title-header">
               <h3>Teljesítményértékelési rendszer bevezetése</h3>
            </div>
            <div class="truck-button">
               <a class="butn" href="docs/Best-Log_Kft-_Teljesitmenyertekelesi_rendszer_bevezetese_2022.pdf" download>Dokumentum letöltése</a>
            </div>
             
         </div>
      </div>
   </section>
</template>
 
<script>
import { info } from '@/assets/js/company.js'; 
import { domain } from '@/assets/js/domain.js'; 
export default {
  name: 'ImpressumTitle',
  data(){
    return { 
        company:info,
        domain: domain
        } 
  }
}

</script>