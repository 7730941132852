<template>
   <section class="py-6 py-md-16 py-lg-20 py-xxl-24 bg-img cover-background parallax dark-overlay main-slider" data-overlay-dark="7" data-background="img/banner/banner-04.jpg" >
      <div class="container pt-1-9 pt-sm-6 pt-md-0 mt-n1-9">
         <div class="row align-items-center position-relative z-index-1 justify-content-center py-xxl-7">
            <div class="col-md-10 text-center py-5">
               <span class="text-primary text-uppercase small letter-spacing-4 d-block mb-2 font-weight-600 wow text-animation" data-in-effect="fadeInRight">
               Áruszállítás . Gumiszerelés . Futómű . Autómosó . Raktározás
               </span>
               <h1 class="display-19 display-md-4 display-lg-3 display-xl-1 mb-0 text-shadow text-white font-weight-800 wow fadeInUp" data-wow-delay="400ms">
                  Nemzetközi és belföldi <span class="title-sm banner">szállítmányozás.</span>
               </h1>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {
  name: 'Banner',
}
</script>