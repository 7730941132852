export const info = {
    name:'Best-Log Kft',
    address: '6065 Lakitelek, Liget utca 9.',
    address2:'6065 Lakitelek, Széchenyi krt. 160.',
    phone: '+36 70 455 5275',
    email:'info@bestlog.hu',
    number:'03 09 117671',
    tax:'14662654-2-03',
    facebook:'',
    linkedIn:'',
    instagram:'',
}